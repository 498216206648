import React from 'react';

import Header from 'components/Contact/Header';
import ContactPeople from 'components/Contact/ContactPeople';
import CareerSection from 'components/Contact/CareerSection';
import Layout from 'components/Layout';
import ScrollTopArrow from 'utilities/scrollToTop';

import contactPic from 'images/contact.jpg';

const Contact = () => (
  <Layout title="Kontakt" description="Kontakt Tel:+387 37 689 113 Fax:+387 37 680 400 E-mail:gat@bih.net.ba" image={contactPic}>
    <ScrollTopArrow />
    <Header />
    <ContactPeople />
    <CareerSection />
  </Layout>
);

export default Contact;
