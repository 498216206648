import React, { useContext } from 'react';
import BackgroundImage from 'gatsby-background-image';

import useContactPageData from 'queries/ContactQueries';
import { LanguageContext } from 'components/Layout';

import styles from './contact.module.scss';

const Header = () => {
  const { language } = useContext(LanguageContext);
  const { headerTitle, headerBgImage } = useContactPageData();
  return (
    <BackgroundImage fluid={headerBgImage} className={styles.headerImage}>
      <h2 className={styles.title}>{headerTitle[`title_${language}`]}</h2>
    </BackgroundImage>
  );
};

export default Header;
