import { graphql, useStaticQuery } from 'gatsby';

const useContactPageData = () => {
  const { allStrapiContactPeople, allStrapiContactPageDetails } = useStaticQuery(graphql`
    query {
      allStrapiContactPeople(sort: {fields: created_at}) {
        nodes {
          strapiId
          created_at
          name
          position_bs
          position_en
          position_de
          number
          email
          avatar {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allStrapiContactPageDetails {
        nodes {
          title_bs
          title_en
          title_de
          top_background {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button_bs
          button_en
          button_de
          subtitle_bs
          subtitle_en
          subtitle_de
          bottom_background {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return {
    contactPeople: allStrapiContactPeople.nodes.map((person) => person),
    headerTitle: {
      title_bs: allStrapiContactPageDetails.nodes[0].title_bs,
      title_en: allStrapiContactPageDetails.nodes[0].title_en,
      title_de: allStrapiContactPageDetails.nodes[0].title_de,
    },
    headerBgImage: allStrapiContactPageDetails.nodes[0].top_background.childImageSharp.fluid,
    buttonText: {
      button_bs: allStrapiContactPageDetails.nodes[0].button_bs,
      button_en: allStrapiContactPageDetails.nodes[0].button_en,
      button_de: allStrapiContactPageDetails.nodes[0].button_de,
    },
    subtitle: {
      subtitle_bs: allStrapiContactPageDetails.nodes[0].subtitle_bs,
      subtitle_en: allStrapiContactPageDetails.nodes[0].subtitle_en,
      subtitle_de: allStrapiContactPageDetails.nodes[0].subtitle_de,
    },
    careerBgImage: allStrapiContactPageDetails.nodes[0].bottom_background.childImageSharp.fluid,
  };
};

export default useContactPageData;
