import React, { useContext } from 'react';
import BackgroundImage from 'gatsby-background-image';

import { LanguageContext } from 'components/Layout';
import useContactPageData from 'queries/ContactQueries';

import styles from './contact.module.scss';

const CareerSection = () => {
  const { language } = useContext(LanguageContext);
  const { buttonText, subtitle, careerBgImage } = useContactPageData();
  return (
    <BackgroundImage fluid={careerBgImage} className={styles.backgroundImage}>
      <div className={styles.careerSection}>
        <a href="https://gat-cnc.talentlyft.com/#home" target="_blank"><button type="button" className={styles.careerButton}>{buttonText[`button_${language}`]}</button></a>
        <p>{subtitle[`subtitle_${language}`]}</p>
      </div>
    </BackgroundImage>
  );
};

export default CareerSection;
