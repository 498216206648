import React, { useContext } from 'react';
import Image from 'gatsby-image';

import { LanguageContext } from 'components/Layout';
import useContactPageData from 'queries/ContactQueries';

import styles from './contact.module.scss';

const ContactPeople = () => {
  const { language } = useContext(LanguageContext);
  const { contactPeople } = useContactPageData();

  const contactUsText = {
    bs: 'Pišite nam',
    en: 'Contact us',
    de: 'Kontakt',
  };

  return (
    <div className={styles.contactsContainer}>
      {
        contactPeople.filter( (person, index) => index === contactPeople.findIndex( element => element.name === person.name )).map((person) => (
          <div key={`${person.strapiId}`} className={styles.contactCard}>
            <Image fluid={person.avatar.childImageSharp.fluid} className={styles.avatar} />
            <a href={`mailto:${person.email}`}><button type="button" className={styles.contactButton}>{contactUsText[language]}</button></a>
            <div className={styles.info}>
              <h2>{person.name}</h2>
              <h3>{person[`position_${language}`]}</h3>
              {
                person.email.split('/').map((email) => (
                  <p key={email}>{email}</p>
                ))
              }
              {
                person.number.split('/').map((number) => (
                  <p key={number}>{number}</p>
                ))
            }
            </div>
            <hr className={styles.divider} />
          </div>
        ))
    }
    </div>
  );
};

export default ContactPeople;
